import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import styles from '../../styles/pages/trivia-game.module.scss';

// import GameComponent from '../GameComponent';
// import Screen3 from '../../assets/scoreboard.png';

// import Iframe from 'react-iframe'

<script src="https://kit.fontawesome.com/70accd3f45.js" crossorigin="anonymous"></script>;

const TriviaGame = ({ history, setVideoToPlay }) => {
  useEffect(() => {
    if (isMobile) {
      history.push('/trivia-game');
    }
  }, []);

  const renderPrevArrow = () => {
    if (isMobile) {
      return (
        <Link to="/expert-videos" style={{ textDecoration: 'none' }}>
          <div
            className="left-arrow"
            style={{ zIndex: 1 }}
          >
            <i className="fas fa-arrow-left arrow-style"></i>
            <div className="left-right-text">
              Expert
              <br />
              Videos
            </div>
          </div>
        </Link>
      );
    }
    return (
      <div
        className="left-arrow"
        style={{ zIndex: 1 }}
        onClick={() => setVideoToPlay('trivia-game-expert-videos')}
      >
        <i className="fas fa-arrow-left arrow-style"></i>
        <div className="left-right-text">
          Expert
          <br />
          Videos
        </div>
      </div>
    );
  };

  const renderNextArrow = () => {
    if (isMobile) {
      return (
        <Link to="/resource-library" style={{ textDecoration: 'none' }}>
          <div
            className="right-arrow"
            style={{ zIndex: 1 }}
          >
            <i className="fas fa-arrow-right arrow-style"></i>
            <div className="left-right-text">
              Resource
              <br />
              Library
            </div>
          </div>
        </Link>
      );
    }
    return (
      <div
        className="right-arrow"
        style={{ zIndex: 1 }}
        onClick={() => setVideoToPlay('trivia-game-resource-library')}
      >
        <i className="fas fa-arrow-right arrow-style"></i>
        <div className="left-right-text">
          Resource
          <br />
          Library
        </div>
      </div>
    );
  };

  return (
    <div className={`${styles['trivia-page-wrapper-0']}`}>
      {renderPrevArrow()}
      <Link to="/trivia-game" style={{ height: '100%', width: '100%', zIndex: 1 }}></Link>
      {renderNextArrow()}
    </div>
  );
};

export default TriviaGame;
