import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { isMobile } from "react-device-detect";
import ReactGA from 'react-ga';
import styles from '../../styles/pages/expert-videos.module.scss';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';

import { useParams } from 'react-router-dom';

<script src="https://kit.fontawesome.com/70accd3f45.js" crossorigin="anonymous"></script>;

let videoElements = [
  {
    name: 'Video 1',
    thumbnails: [
      'https://d59nx84ake9tn.cloudfront.net/jazz-booth/KOLs_screensaver_vid_1_KOLs.png',
      'https://d59nx84ake9tn.cloudfront.net/jazz-booth/KOLs_screensaver_vid_1_Topic.png'
    ],
    // src: 'https://d59nx84ake9tn.cloudfront.net/jazz-booth/041221a/VA_JZP_7546_KOL_A_Definitive_Diagnosis_v7_compressed.mp4',
    src: 'https://player.vimeo.com/video/535300682',
    alt: 'Video 1'
  },
  {
    name: 'Video 2',
    thumbnails: [
      'https://d59nx84ake9tn.cloudfront.net/jazz-booth/KOLs_screensaver_vid_2_KOLs.png',
      'https://d59nx84ake9tn.cloudfront.net/jazz-booth/KOLs_screensaver_vid_2_Topic.png'
    ],
    // src: 'https://d59nx84ake9tn.cloudfront.net/jazz-booth/041221a/VA_JZP_7546_KOL_Comorbidities_Hypocretin_Deficiency_v10_compressed.mp4',
    src: 'https://player.vimeo.com/video/535300606',
    alt: 'Video 2'
  },
  {
    name: 'Video 3',
    thumbnails: [
      'https://d59nx84ake9tn.cloudfront.net/jazz-booth/KOLs_screensaver_vid_3_KOLs.png',
      'https://d59nx84ake9tn.cloudfront.net/jazz-booth/KOLs_screensaver_vid_3_Topic.png'
    ],
    src:
      // 'https://d59nx84ake9tn.cloudfront.net/jazz-booth/041221a/VA_JZP_7546_KOL_Disease_Management-Lifestyle_Modifications_v11_compressed.mp4',
      'https://player.vimeo.com/video/535300628',
    alt: 'Video 3'
  },
  {
    name: 'Video 4',
    thumbnails: [
      'https://d59nx84ake9tn.cloudfront.net/jazz-booth/KOLs_screensaver_vid_4_KOLs.png',
      'https://d59nx84ake9tn.cloudfront.net/jazz-booth/KOLs_screensaver_vid_4_Topic.png'
    ],
    // src: 'https://d59nx84ake9tn.cloudfront.net/jazz-booth/041221a/VA_JZP_7546_KOL_Sodium_Intake_v8_compressed.mp4',
    src: 'https://player.vimeo.com/video/535300655',
    alt: 'Video 4'
  }
];

const ExpertVideos = ({ setVideoToPlay }) => {
  Modal.setAppElement('#root');
  const [isOpen, setIsOpen] = useState(false);
  const [modalContentLabel, setModalContentLabel] = useState('');
  const [iframeSrc, setIframeSrc] = useState('');
  const { id } = useParams();

  const [loopIndex, setLookIndex] = useState(0);

  useEffect(() => {
    setVideoToPlay('');
    if (id !== undefined) {
      openModal(videoElements[parseInt(id)].src, videoElements[parseInt(id)].name);
    }
    const interval = setInterval(() => {
      setLookIndex(prevIndex => prevIndex === 0 ? 1 : 0);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  const openModal = (src, label) => {
    setIsOpen(!isOpen);
    setIframeSrc(src);
    setModalContentLabel(label);
  };

  const renderVideoButton = (videoElement, index) => (
    <div className={`${styles['videos']} ${styles['col']}`} style={{ maxWidth: '100%', maxHeight: '100%', position: 'relative' }}>
      <img
        onClick={() => {
          ReactGA.event({
            category: 'Click',
            action: 'Expert Video',
            label: videoElement.name
          });
          openModal(videoElement.src, videoElement.name);
	}}
        className={`${styles['transition-expert-video']} ${index === 1 ? styles['transition-expert-video-out'] : styles['transition-expert-video-in']}`}
        src={videoElement.thumbnails[0]}
        alt={videoElement.alt}
        style={{ position: 'absolute', maxWidth: '100%', maxHeight: '100%' }}
      />
      <img
        onClick={() => openModal(videoElement.src, videoElement.name)}
        src={videoElement.thumbnails[1]}
        alt={videoElement.alt}
        style={{ maxWidth: '100%', maxHeight: '100%' }}
      />
    </div>
  );

  const renderPrevArrow = () => {
    if (isMobile) {
      return (
        <Link to="/home" style={{ textDecoration: 'none' }}>
          <div className="left-arrow">
            <i className="fas fa-arrow-left arrow-style"></i>
            <div className="left-right-text">Home</div>
          </div>
        </Link>
      );
    }
    return (
      <div className="left-arrow" onClick={() => setVideoToPlay('expert-videos-home')}>
        <i className="fas fa-arrow-left arrow-style"></i>
        <div className="left-right-text">Home</div>
      </div>
    );
  };

  const renderNextArrow = () => {
    if (isMobile) {
      return (
        <Link to="/trivia-game" style={{ textDecoration: 'none' }}>
          <div className="right-arrow">
            <i className="fas fa-arrow-right arrow-style"></i>
            <div className="left-right-text">
              Trivia
              <br />
              Game
            </div>
          </div>
        </Link>
      );
    }
    return (
      <div className="right-arrow" onClick={() => setVideoToPlay('expert-videos-trivia-game')}>
        <i className="fas fa-arrow-right arrow-style"></i>
        <div className="left-right-text">
          Trivia
          <br />
          Game
        </div>
      </div>
    );
  };

  return (
    <div className={styles['expert-video-layout']}>
      {renderPrevArrow()}
      <div className={styles['expert-videos']}>
        <div className={styles['header']}>
          <span className={styles['bold']}>WATCH LEADING SLEEP SPECIALISTS</span> discuss the burden
          of comorbidities and the importance of long-term disease management in patients with
          narcolepsy.
        </div>
        <div className={styles['mobile-header']}>WATCH EXPERTS GIVE INSIGHTS ON KEY TOPICS</div>

        <div className={styles['flex-grid']}>
          {renderVideoButton(videoElements[0], loopIndex)}
          {renderVideoButton(videoElements[1], loopIndex)}
        </div>
        <div className={styles['flex-grid']}>
          {renderVideoButton(videoElements[2], loopIndex)}
          {renderVideoButton(videoElements[3], loopIndex)}
        </div>


        <Modal
          isOpen={isOpen}
          onRequestClose={() => setIsOpen(!isOpen)}
          contentLabel={modalContentLabel}
          className={styles['modal']}
          overlayClassName={styles['modal-overlay']}
          closeTimeoutMS={500}
        >
          <button
            onClick={() => setIsOpen(!isOpen)}
            className={styles['modal-close']}
            style={{ display: 'block', marginRight: 10 }}
          >
            <i className={'fas fa-times'}></i>
          </button>
          <div className={styles['video-player']}>
            <ReactPlayer playing controls url={iframeSrc} width={'100%'} height={'100%'} />
          </div>
        </Modal>
      </div>
      {renderNextArrow()}
    </div>
  );
};

export default ExpertVideos;
