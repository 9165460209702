import React, { useEffect } from 'react';
import _ from 'lodash';
import styles from '../../styles/pages/contact-us.module.scss';
import { Link } from 'react-router-dom';

import config from '../config';

<script src="https://kit.fontawesome.com/70accd3f45.js" crossorigin="anonymous"></script>;

const cleanFormData = { name: '', email: '', message: '' };

const ContactUs = ({ setVideoToPlay }) => {
  const [data, setData] = React.useState(cleanFormData);
  const [submitted, setSubmitted] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState(false);

  useEffect(() => {
    setVideoToPlay('');
  }, []);

  const submitForm = () => {
    // submit to api endpoint

    setSubmitting(true);
    setError(false);

    config
      .fetch(`${config.baseApiUrl}/api/contact`, {
        method: 'POST',
        body: JSON.stringify(data)
      })
      .then((response) => {
        if (response.status !== 201) {
          // error happened
          setSubmitted(false);
          setSubmitting(false);
          setError(true);
        } else {
          setSubmitted(true);
          setError(false);
          setSubmitting(false);
          setData(cleanFormData);
        }
      });
  };

  const handleFieldChange = (fieldName) => {
    return (event) => {
      setData((prevData) => {
        return { ...prevData, [fieldName]: event.target.value };
      });
    };
  };

  const submitButtonDisabled = () => {
    return submitting || !_.every(_.values(data).map((val) => val.trim()));
  };

  const renderContent = () => {
    if (submitted) {
      return (
        <>
          <h1 className={`${styles['main-text']}`}>
            Your request has been successfully submitted!
          </h1>
          <div className={styles.center} style={{ marginTop: '20px', marginBottom: '20px' }}>
            <input
              className={styles.btn}
              type="submit"
              value="CLOSE"
              onClick={() => setSubmitted(false)}
            />
          </div>
        </>
      );
    }
    return (
      <>
        <h1 className={`${styles['main-text']}`}>We Want To Hear From You</h1>
        <form>
          <label>
            Name
            <br />
            <input type="text" name="name" value={data.name} onChange={handleFieldChange('name')} />
          </label>
          <br />

          <label>
            Email
            <br />
            <input
              type="text"
              name="email"
              value={data.email}
              onChange={handleFieldChange('email')}
            />
          </label>
          <br />

          <label>
            Please submit your questions, comments or requests.
            <br />
            Thank you!
            <br />
            {/* <input type="text" name="questions" /> */}
            <textarea
              name="text"
              rows="10"
              value={data.message}
              onChange={handleFieldChange('message')}
            />
          </label>
          <br />
          {error && (
            <p style={{ textAlign: 'center', marginBottom: 10, color: 'red' }}>
              Something went wrong. Please try again later...
            </p>
          )}

          <div className={styles.center}>
            <input
              className={styles.btn}
              type="submit"
              value="SEND"
              onClick={submitForm}
              disabled={submitButtonDisabled()}
            />
          </div>
        </form>
      </>
    );
  };

  return (
    <div className={`${styles['contact-page-layout']}`}>
      <Link to="/resource-library" style={{ textDecoration: 'none' }}>
        <div className="left-arrow">
          <i className="fas fa-arrow-left arrow-style"></i>
          <div className="left-right-text" style={{ textDecoration: 'none' }}>
            Resource
            <br />
            Library
          </div>
        </div>
      </Link>

      <div className={`${styles['contact-us']}`}>
        <div className={`${styles['resource-contact-us-mobile-header']}`}>
          EXPLORE THE DATA, <br />
          ACCESS THE RESOURCES
        </div>

        <div className={styles.container}>
          <div className={`${styles['form-container']}`}>{renderContent()}</div>
        </div>
      </div>

      <Link to="/home" style={{ textDecoration: 'none' }} onClick={() => setVideoToPlay('home-loop')}>
        <div className="right-arrow">
          <i className="fas fa-arrow-right arrow-style"></i>
          <div className="left-right-text">Home</div>
        </div>
      </Link>
    </div>
  );
};
export default ContactUs;
