import React, { useEffect } from 'react';
import ReactPlayer from 'react-player';
import { isMobile } from "react-device-detect";
import Page from './Page';
import styles from '../styles/intro.module.scss';

const Intro = (props) => {
  const { history } = props;

  useEffect(() => {
    if (isMobile) {
      history.push('/home');
    } else {
      props.setVideoToPlay('intro');
    }
  }, []);

  return (
    <Page className={styles.intro}>
    </Page>
  );
};

export default Intro;
