import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import styles from '../../styles/pages/trivia-game.module.scss';
import config from '../config';
import { isMobile } from "react-device-detect";

// import GameComponent from '../GameComponent';
// import Screen3 from '../../assets/scoreboard.png';

// import Iframe from 'react-iframe'

<script src="https://kit.fontawesome.com/70accd3f45.js" crossorigin="anonymous"></script>;

let checkWindow = null;

window.checkWindow = () => {
  const mediaQueryMobileCheck = window.matchMedia(`(max-width: 700px)`);
  if (mediaQueryMobileCheck) {
    console.log('true');
    alert('true');
    return true;
  } else {
    console.log('false');
    alert('false');
    return false;
  }
}

const TriviaGame = ({ history, setVideoToPlay }) => {
  const [isGameFullScreen, setIsGameFullScreen] = useState(false);
  const [leaderboardRandomKey, setLeaderboardRandomKey] = useState(0);

  useEffect(() => {
    const handleMessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data.message === 'Expand') {
          setIsGameFullScreen(!isGameFullScreen);
        } else if (data.message === 'RefreshLeaderboard') {
          setLeaderboardRandomKey(leaderboardRandomKey + 1);
        }
      } catch (e) {
        // whatever, do nothing
      }
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [isGameFullScreen, leaderboardRandomKey]);


  useEffect(() => {
    setVideoToPlay('');    
  }, []);















// set 1

  const iframeRef = useRef(null);

  useEffect(() => {
    iframeRef.current.addEventListener('load', () => {
      window.addEventListener('load', () => {
        window.addEventListener('resize', () => {
          checkWindow();
          console.log(this,)
        })
      })
    });
  }, []);





  // set 2

  // const leaderboardIframe = document.getElementById("seekAndHide");

  // // on iframe load...
  // leaderboardIframe.addEventListener("load", function() {
    
  //   const seekAndHide = document.querySelectorAll('.seekAndHide');
  //   if (window.matchMedia("(max-width: 700px)").matches) {
  //     // hide seekAndHide
  //   } else {
  //     // showw seekAndHide
  //   }
  // });

  // // on resize ...
  // leaderboardIframe.addEventListener("resize", function() {
  //   const leaderboardIframe = document.getElementById("leaderboardFix");
  //   const seekAndHide = document.querySelectorAll('.seekAndHide');
  //   if (window.matchMedia("(max-width: 700px)").matches) {
  //     // hide seekAndHide
  //   } else {
  //     // showw seekAndHide
  //   }
  // });



  // const onLoad = () => {
  //   alert('iframe loaded?');
  // }



  const renderPrevArrow = () => {
    if (isMobile) {
      return (
        <Link to="/expert-videos" style={{ textDecoration: 'none' }}>
          <div
            className="left-arrow"
            // style={{ zIndex: 1 }}
          >
            <i className="fas fa-arrow-left arrow-style"></i>
            <div className="left-right-text">
              Expert
              <br />
              Videos
            </div>
          </div>
        </Link>
      );
    }
    return (
      <div
        className="left-arrow"
        // style={{ zIndex: 1 }}
        onClick={() => setVideoToPlay('trivia-game-expert-videos')}
      >
        <i className="fas fa-arrow-left arrow-style"></i>
        <div className="left-right-text">
          Expert
          <br />
          Videos
        </div>
      </div>
    );
  };

  const renderNextArrow = () => {
    if (isMobile) {
      return (
        <Link to="/resource-library" style={{ textDecoration: 'none' }}>
          <div
            className="right-arrow"
            // style={{ zIndex: 1 }}
          >
            <i className="fas fa-arrow-right arrow-style"></i>
            <div className="left-right-text">
              Resource
              <br />
              Library
            </div>
          </div>
        </Link>
      );
    }
    return (
      <div
        className="right-arrow"
        // style={{ zIndex: 1 }}
        onClick={() => setVideoToPlay('trivia-game-resource-library')}
      >
        <i className="fas fa-arrow-right arrow-style"></i>
        <div className="left-right-text">
          Resource
          <br />
          Library
        </div>
      </div>
    );
  };


  return (
    <div className={`${styles['trivia-page-wrapper']}`}>
      {renderPrevArrow()}
      <div className={`${styles['trivia-column-wrapper']}`}>
        <div className={`${styles['trivia-page-title-text']}`}>
          CONSIDER YOURSELF A NARCOLEPSY EXPERT? QUIZZZZ YOURSELF...
        </div>
        <div className={`${styles['trivia-page-eyebrow']}`}>
          Correctly answer more narcolepsy-related questions than your peers and see your name at
          the top of our leaderboard
        </div>

        <div className={`${styles['trivia-game-layout']}`}>
          {/* <GameComponent /> */}

          {/* <img src={Screen3} className="scoreboard-img" style={{ height: 600 }}></img> */}
          <div className={`${styles['mobile-flip']}`}>
            <iframe
              src={`${config.gameUrl}/?marqueeLeaderboard=1`}
              height="100%"
              // height="600px"
              title="Trivia Game Leaderboard"
              key={leaderboardRandomKey}
              className={`${styles['iframe-leaderboard']}`}
              id="leaderboardFix"
              onload="onLoad()"
            />

            <iframe
              ref={iframeRef}
              src={`${config.gameUrl}/`}
              height="100%"
              // height="600px"
              title="Trivia Game"
              className={`${isGameFullScreen ? styles['full-screen'] : ''} ${
              styles['iframe-game']
              }`}
            />
          </div>
        </div>
      </div>
      {renderNextArrow()}
    </div>
  );
};

export default TriviaGame;
