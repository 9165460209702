import React, { useState, useEffect, useRef } from 'react';
import './App.scss';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import { isMobile } from "react-device-detect";
import Analytics from 'react-router-ga';
import VideoPlayer from './components/TransitionVideo';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { PrivateRoutes } from './components/Routes';
import { Provider } from './components/Context';
import { Link } from 'react-router-dom';
import dropdownStyles from './styles/dropdown.module.scss';

ReactGA.initialize('UA-15348392-60');

const App = (props) => {
  const [hasRanIntro, setHasRanIntro] = useState(false);
  const [hasSeenDisclaimer, setHasSeenDisclaimer] = useState(false);
  const [isDesktopExpertVideosSubNavActive, setIsDesktopExpertSubNavActive] = useState(false);
  const [isDesktopResourceLibrarySubNavActive, setIsDesktopResourceLibrarySubNavActive] = useState(
    false
  );
  const [videoToPlay, setVideoToPlay] = useState('');

  const expertVideosSubNavRef = useRef(null);
  const resourceLibrarySubNavRef = useRef(null);
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          closeHamburger();
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideAlerter(expertVideosSubNavRef);
  useOutsideAlerter(resourceLibrarySubNavRef);

  const closeHamburger = () => {
    setIsDesktopExpertSubNavActive(false);
    setIsDesktopResourceLibrarySubNavActive(false);
  };

  if (window.location.pathname !== '/disclaimer' && !hasSeenDisclaimer) {
    window.location.pathname = '/disclaimer';
  }

  return (
    <Provider
      value={{
        hasRanIntro: hasRanIntro,
        setHasSeenDisclaimer
      }}
    >
      <BrowserRouter>
        <Analytics id="UA-15348392-60">
          <Navbar
            setIsDesktopResourceLibrarySubNavActive={setIsDesktopResourceLibrarySubNavActive}
            isDesktopResourceLibrarySubNavActive={isDesktopResourceLibrarySubNavActive}
            setIsDesktopExpertSubNavActive={setIsDesktopExpertSubNavActive}
            isDesktopExpertVideosSubNavActive={isDesktopExpertVideosSubNavActive}
	    setVideoToPlay={setVideoToPlay}
          />
          {!isMobile &&
          <div style={{ flex: 1, position: 'relative', display: 'flex' }}>
            <VideoPlayer videoToPlay={videoToPlay} setVideoToPlay={setVideoToPlay} />
            <PrivateRoutes setVideoToPlay={setVideoToPlay} />
          </div>}
          {isMobile && <PrivateRoutes setVideoToPlay={setVideoToPlay} />}
          {isDesktopExpertVideosSubNavActive && (
            <ul
              className={`${dropdownStyles['dropdown-menu']} ${dropdownStyles['desktop-expert-videos']}`}
              ref={expertVideosSubNavRef}
            >
              <li>
                <Link
                  className={dropdownStyles['dropdown-link']}
                  to="/expert-videos/0"
                  onClick={closeHamburger}
                >
                  A Definitive Diagnosis of Narcolepsy
                </Link>
              </li>
              <li>
                <Link
                  className={dropdownStyles['dropdown-link']}
                  to="/expert-videos/1"
                  onClick={closeHamburger}
                >
                  Comorbidities and Hypocretin Deficiency in Narcolepsy
                </Link>
              </li>
              <li>
                <Link
                  className={dropdownStyles['dropdown-link']}
                  to="/expert-videos/2"
                  onClick={closeHamburger}
                >
                  Disease Management Considerations and Lifestyle Modifications
                </Link>
              </li>
              <li>
                <Link
                  className={dropdownStyles['dropdown-link']}
                  to="/expert-videos/3"
                  onClick={closeHamburger}
                >
                  Sodium Intake in Patients With Narcolepsy
                </Link>
              </li>
            </ul>
          )}
          {isDesktopResourceLibrarySubNavActive && (
            <ul
              className={`${dropdownStyles['dropdown-menu']} ${dropdownStyles['desktop-resource-library']}`}
              ref={resourceLibrarySubNavRef}
            >
              <li>
                <Link
                  className={dropdownStyles['dropdown-link']}
                  to="/resource-library/0"
                  onClick={closeHamburger}
                >
                  NarcolepsyLink.com
                </Link>
              </li>
              <li>
                <Link
                  className={dropdownStyles['dropdown-link']}
                  to="/resource-library/1"
                  onClick={closeHamburger}
                >
                  Brochure
                </Link>
              </li>
            </ul>
          )}
          <Footer />
        </Analytics>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
