import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import Intro from '../Intro';
import { AnimatePresence } from 'framer-motion';
import Home from '../pages/Home';
import ExpertVideos from '../pages/ExpertVideos';
import TriviaGame0 from '../pages/TriviaGame0';
import TriviaGame from '../pages/TriviaGame';
import ResourceLibrary from '../pages/ResourceLibrary';
import ContactUs from '../pages/ContactUs';
import TransitionVideo from '../TransitionVideo';
import Disclaimer from '../Disclaimer';

const PrivateRoutes = ({ setVideoToPlay }) => {
  const location = useLocation();

  return (
    <AnimatePresence exitBeforeEnter>
      <Switch location={location} key={location.pathname}>
        <Route path="/" exact render={(props) => <Intro {...props} setVideoToPlay={setVideoToPlay} />} />
        <Route path="/disclaimer" exact component={Disclaimer} />
        <Route path="/home" exact render={(props) => <Home {...props} setVideoToPlay={setVideoToPlay} />} />
        <Route path="/expert-videos" exact render={(props) => <ExpertVideos {...props} setVideoToPlay={setVideoToPlay} />} />
        <Route path="/expert-videos/:id" exact render={(props) => <ExpertVideos {...props} setVideoToPlay={setVideoToPlay} />} />
        <Route path="/trivia-game0" exact render={(props) => <TriviaGame0 {...props} setVideoToPlay={setVideoToPlay} />} />
        <Route path="/trivia-game" exact render={(props) => <TriviaGame {...props} setVideoToPlay={setVideoToPlay} />} />
        <Route path="/resource-library" exact render={(props) => <ResourceLibrary {...props} setVideoToPlay={setVideoToPlay} />} />
        <Route path="/resource-library/:id" exact render={(props) => <ResourceLibrary {...props} setVideoToPlay={setVideoToPlay} />} />
        <Route path="/contact-us" exact render={(props) => <ContactUs {...props} setVideoToPlay={setVideoToPlay} />} />
        <Route path="/transition/:name" exact component={TransitionVideo} />
      </Switch>
    </AnimatePresence>
  );
};

export { PrivateRoutes };
