// import React from 'react';
import React, { useRef, useState } from 'react';
import Modal from 'react-modal';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import 'react-tabs/style/react-tabs.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import styles from '../styles/resource-tabs.module.scss';
// import BrochureCover from '../assets/brochure-cover.jpg';
// import { findByLabelText } from '@testing-library/dom';

const ResourceTabs = () => {
  const sliderRef = useRef(null);
  const modalSliderRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const [activeModalSlide, setActiveModalSlide] = useState(0);
  const { id } = useParams();
  const settings = {
    arrows: false,
    slidesToShow: 1,
    infinite: false,
    afterChange: (current) => setActiveSlide(current)
  };
  const modalSettings = {
    arrows: false,
    slidesToShow: 1,
    infinite: false,
    afterChange: (current) => setActiveModalSlide(current)
  };

  const prevPage = () => {
    sliderRef.current.slickPrev();
  };
  const nextPage = () => {
    sliderRef.current.slickNext();
  };

  const prevModalPage = () => {
    modalSliderRef.current.slickPrev();
  };
  const nextModalPage = () => {
    modalSliderRef.current.slickNext();
  };
  const prevArrowStyle = () => {
    if (activeSlide > 0) {
      return { visibility: 'visible' };
    }
    return { visibility: 'hidden' };
  };
  const nextArrowStyle = () => {
    if (activeSlide < 11) {
      return { visibility: 'visible' };
    }
    return { visibility: 'hidden' };
  };
  const prevModalArrowStyle = () => {
    if (activeModalSlide > 0) {
      return { visibility: 'visible' };
    }
    return { visibility: 'hidden' };
  };
  const nextModalArrowStyle = () => {
    if (activeModalSlide < 11) {
      return { visibility: 'visible' };
    }
    return { visibility: 'hidden' };
  };

  const expander = document.querySelectorAll('img')[1];

  const toggleModal = () => {
    if (isMobile) {
      setIsOpen(!isOpen);
    }
  };

  // const putExpanderIconBack = () => {
  //   expander.style.visibility = 'visible';
  //   setIsOpen(!isOpen);
  // }

  return (
    // <div className={`${styles['resource-tab-component']}`} class={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '30' }}>
    <div className={`${styles['resource-tab-component']}`}>
      <div
        className={`${styles['resource-header-text']}`}
        style={{
          textAlign: 'center',
          color: '#ffffff',
          fontSize: '24px',
          fontWeight: '600',
          paddingTop: '20px',
          paddingBottom: '20px'
        }}
      >
        EXPLORE THE DATA,
        <br />
        ACCESS THE RESOURCES
      </div>

      <Tabs className={styles['tabs']} defaultIndex={id === undefined ? 0 : parseInt(id)} style={{ width: '800px' }}>
        <TabList className={styles['tab-list']}>
          <Tab>
            <div className={styles['tab']}>NarcolepsyLink.com</div>
          </Tab>
          <Tab>
            <div className={styles['tab']}>Brochure</div>
          </Tab>
        </TabList>

        <TabPanel className={styles['tab-panel']}>
          <div className={styles['tab-header']}></div>
          <div className={styles['tab-link-styling']}>
            <div className={styles['three-eigth']}>&nbsp;</div>
            <div className={styles['one-eigth']}>&nbsp;</div>
            <div className={styles['four-eigth']}>
              <div className={styles['tab-links']}>
                <div>
                  Visit{' '}
                  <a href="https://www.narcolepsylink.com/" rel="noreferrer" target="_BLANK">
                    NarcolepsyLink.com
                  </a>{' '}
                  to
                </div>
                <div style={{ display: 'flex' }}>
                  <i
                    className="fas fa-arrow-circle-right"
                    style={{ paddingTop: 3, marginLeft: 5, marginRight: 10 }}
                  />
                  <span>
                    Hear{' '}
                    <a
                      href="https://www.narcolepsylink.com/resources/education-on-demand/"
                      target="_BLANK"
                      rel="noreferrer"
                    >
                      expert insights
                    </a>
                  </span>
                </div>
                <div style={{ display: 'flex' }}>
                  <i
                    className="fas fa-arrow-circle-right"
                    style={{ paddingTop: 3, marginLeft: 5, marginRight: 10 }}
                  />
                  <span>
                    Explore{' '}
                    <a
                      href="https://www.narcolepsylink.com/comorbidities-risk/risk/"
                      target="_BLANK"
                      rel="noreferrer"
                    >
                      modifiable risk factors
                    </a>{' '}
                    for{' '}
                    <a
                      href="https://www.narcolepsylink.com/comorbidities-risk/comorbidities/"
                      target="_BLANK"
                      rel="noreferrer"
                    >
                      certain comorbidities
                    </a>
                  </span>
                </div>
                <div style={{ display: 'flex' }}>
                  <i
                    className="fas fa-arrow-circle-right"
                    style={{ paddingTop: 3, marginLeft: 5, marginRight: 10 }}
                  />
                  <span>
                    Review{' '}
                    <a
                      href="https://www.narcolepsylink.com/disease-management/treatment-decisions/"
                      target="_BLANK"
                      rel="noreferrer"
                    >
                      management considerations
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel className={styles['tab-panel']}>
          <div className={styles['tab-header']}>
            <h2 className={styles['tab-brochure']}>
              A top-line overview of the impact of narcolepsy and the role of comorbidities in
              disease management
            </h2>
            <div className={styles['tab-icon']}>
              <a
                href="https://mkg-public-assets.s3.amazonaws.com/jazz-booth/brochure_041321.pdf"
                target="_BLANK"
                rel="noreferrer"
              >
                <i className="fas fa-download icon-blue" />
              </a>
            </div>
          </div>

          <div className={styles['tab-body-pdf']} style={{ marginTop: '30px', position: 'relative' }}>
            
            {!isOpen && <img src={'https://va-email-assets.s3.amazonaws.com/jazz-booth/expand-001.png'}
              className={styles['expander']}
              onClick={toggleModal}
              alt=""
              style={{ position: 'absolute', top: '10px', right: '12%', width: '20px', height: '20px', zIndex: 2 }}>
            </img>}

            <div className="left-chevron" onClick={prevPage} style={prevArrowStyle()}>
              <i className="fal fa-chevron-circle-left arrow-style"></i>
              <div className="left-right-text">Previous page</div>
            </div>
            <Slider {...settings} style={{ width: '80%', position: 'relative' }} ref={sliderRef}>
              <img
                alt=""
                src={'https://va-email-assets.s3.amazonaws.com/jazz-booth/Page_01.jpg'}
                className={styles['pdf']}
                onClick={toggleModal}
              ></img>
              <img
                alt=""
                src={'https://va-email-assets.s3.amazonaws.com/jazz-booth/Page_02.jpg'}
                className={styles['pdf']}
                onClick={toggleModal}
              ></img>
              <img
                alt=""
                src={'https://va-email-assets.s3.amazonaws.com/jazz-booth/Page_03.jpg'}
                className={styles['pdf']}
                onClick={toggleModal}
              ></img>
              <img
                alt=""
                src={'https://va-email-assets.s3.amazonaws.com/jazz-booth/Page_04.jpg'}
                className={styles['pdf']}
                onClick={toggleModal}
              ></img>
              <img
                alt=""
                src={'https://va-email-assets.s3.amazonaws.com/jazz-booth/Page_05.jpg'}
                className={styles['pdf']}
                onClick={toggleModal}
              ></img>
              <img
                alt=""
                src={'https://va-email-assets.s3.amazonaws.com/jazz-booth/Page_06.jpg'}
                className={styles['pdf']}
                onClick={toggleModal}
              ></img>
              <img
                alt=""
                src={'https://va-email-assets.s3.amazonaws.com/jazz-booth/Page_07.jpg'}
                className={styles['pdf']}
                onClick={toggleModal}
              ></img>
              <img
                alt=""
                src={'https://va-email-assets.s3.amazonaws.com/jazz-booth/Page_08.jpg'}
                className={styles['pdf']}
                onClick={toggleModal}
              ></img>
              <img
                alt=""
                src={'https://va-email-assets.s3.amazonaws.com/jazz-booth/Page_09.jpg'}
                className={styles['pdf']}
                onClick={toggleModal}
              ></img>
              <img
                alt=""
                src={'https://va-email-assets.s3.amazonaws.com/jazz-booth/Page_10.jpg'}
                className={styles['pdf']}
                onClick={toggleModal}
              ></img>
              <img
                alt=""
                src={'https://va-email-assets.s3.amazonaws.com/jazz-booth/Page_11.jpg'}
                className={styles['pdf']}
                onClick={toggleModal}
              ></img>
              <img
                alt=""
                src={'https://va-email-assets.s3.amazonaws.com/jazz-booth/Page_12.jpg'}
                className={styles['pdf']}
                onClick={toggleModal}
              ></img>
            </Slider>
            <div className="right-chevron" onClick={nextPage} style={nextArrowStyle()}>
              <i className="fal fa-chevron-circle-right arrow-style"></i>
              <div className="left-right-text">Next page</div>
            </div>
          </div>
          <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            contentLabel={'Brochure'}
            className={styles['modal']}
            overlayClassName={styles['modal-overlay']}
            closeTimeoutMS={500}
          >
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
              <div style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>
                <button
                  className={styles['modal-close']}
                  style={{ display: 'flex', flex: 1, justifyContent: 'flex-start' }}
                >
                  <a
                    href="https://mkg-public-assets.s3.amazonaws.com/brochure.pdf"
                    target="_BLANK"
                    rel="noreferrer"
                  >
                    {/* <i className={'fas fa-arrow-to-bottom'}></i> */}
                    <i className="fas fa-download icon-blue" />
                  </a>
                </button>
                <button
                  onClick={toggleModal}
                  className={styles['modal-close']}
                  style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}
                >
                  <i className={'fas fa-times'}></i>
                </button>
              </div>
              <Slider {...modalSettings} ref={modalSliderRef}>
                <img
                  src={'https://va-email-assets.s3.amazonaws.com/jazz-booth/Page_01.jpg'}
                  alt=""
                  style={{ zIndex: "3" }}
                ></img>
                <img
                  src={'https://va-email-assets.s3.amazonaws.com/jazz-booth/Page_02.jpg'}
                  alt=""
                  style={{ zIndex: "3" }}
                ></img>
                <img
                  src={'https://va-email-assets.s3.amazonaws.com/jazz-booth/Page_03.jpg'}
                  alt=""
                  style={{ zIndex: "3" }}
                ></img>
                <img
                  src={'https://va-email-assets.s3.amazonaws.com/jazz-booth/Page_04.jpg'}
                  alt=""
                  style={{ zIndex: "3" }}
                ></img>
                <img
                  src={'https://va-email-assets.s3.amazonaws.com/jazz-booth/Page_05.jpg'}
                  alt=""
                  style={{ zIndex: "3" }}
                ></img>
                <img
                  src={'https://va-email-assets.s3.amazonaws.com/jazz-booth/Page_06.jpg'}
                  alt=""
                  style={{ zIndex: "3" }}
                ></img>
                <img
                  src={'https://va-email-assets.s3.amazonaws.com/jazz-booth/Page_07.jpg'}
                  alt=""
                  style={{ zIndex: "3" }}
                ></img>
                <img
                  src={'https://va-email-assets.s3.amazonaws.com/jazz-booth/Page_08.jpg'}
                  alt=""
                  style={{ zIndex: "3" }}
                ></img>
                <img
                  src={'https://va-email-assets.s3.amazonaws.com/jazz-booth/Page_09.jpg'}
                  alt=""
                  style={{ zIndex: "3" }}
                ></img>
                <img
                  src={'https://va-email-assets.s3.amazonaws.com/jazz-booth/Page_10.jpg'}
                  alt=""
                  style={{ zIndex: "3" }}
                ></img>
                <img
                  src={'https://va-email-assets.s3.amazonaws.com/jazz-booth/Page_11.jpg'}
                  alt=""
                  style={{ zIndex: "3" }}
                ></img>
                <img
                  src={'https://va-email-assets.s3.amazonaws.com/jazz-booth/Page_12.jpg'}
                  alt=""
                  style={{ zIndex: "3" }}
                ></img>
              </Slider>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  margin: 10,
                  justifyContent: 'center'
                }}
              >
                <div
                  className={styles['modal-left-chevron']}
                  onClick={prevModalPage}
                  style={prevModalArrowStyle()}
                >
                  <i className="fal fa-chevron-circle-left arrow-style"></i>
                  <div className="left-right-text">Previous page</div>
                </div>
                <div
                  className={styles['modal-right-chevron']}
                  onClick={nextModalPage}
                  style={nextModalArrowStyle()}
                >
                  <i className="fal fa-chevron-circle-right arrow-style"></i>
                  <div className="left-right-text">Next page</div>
                </div>
              </div>
            </div>
          </Modal>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default ResourceTabs;
