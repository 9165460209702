import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/footer.module.scss';
import Logo from '../assets/jazz-logo-white.png';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Link to="/home" className={`${styles['footer-logo-section']}`}>
        <img src={Logo} alt="Jazz Pharmaceuticals" style={{ width: '75%', float: 'left' }} />
      </Link>
      <div className={`${styles['footer-copy']}`}>
        &copy;2021 Jazz Pharmaceuticals plc or its subsidiaries. US&#8209;SLE&#8209;2100005&nbsp;REV0421
        <br />
        Narcolepsy Link is sponsored by Jazz Pharmaceuticals
      </div>
    </footer>
  );
};
export default Footer;
