import React from 'react';
import { Consumer } from './Context';
import btnstyles from '../styles/button.module.scss';

const Disclaimer = (props) => {
  return (
    <Consumer>
      {(context) => {
        return (
          <div
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              backgroundColor: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <h1 className={`${btnstyles['disclaimer-title']}`}>This site is intended for US healthcare professionals</h1>
            <p className={`${btnstyles['disclaimer-eyebrow']}`}>I acknowledge that I am a US healthcare professional</p>
            <div
              style={{
                display: 'flex',
                width: '50%',
                justifyContent: 'center',
                marginTop: 10
              }}
            >
              <div
                // className="disclaimer-btn"
                className={`${btnstyles['disclaimer-btn']}`}
                onClick={() => {
                  window.open('https://www.morethantired.com');
                }}
              >
                No
              </div>
              <div
                // className="disclaimer-btn"
                className={`${btnstyles['disclaimer-btn']}`}
                onClick={() => {
                  context.setHasSeenDisclaimer(true);
                  props.history.push('/');
                }}
              >
                Yes
              </div>
            </div>
          </div>
        );
      }}
    </Consumer>
  );
};

export default Disclaimer;
