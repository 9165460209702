import React, { useRef } from 'react';
import ReactPlayer from 'react-player';
import { isMobile } from "react-device-detect";
import { useHistory } from 'react-router-dom';

const TransitionMap = {
  'intro': {
    // 'url': 'https://d59nx84ake9tn.cloudfront.net/jazz-booth/Virtual+Jazz+Booth+intro+3.mp4',
    // 'url': 'https://mkg-public-assets.s3.amazonaws.com/jazz-booth/041221a/Virtual_Jazz_Booth_intro_4.mp4',
    'url': 'https://mkg-public-assets.s3.amazonaws.com/jazz-booth/041321/Virtual_Jazz_Booth_intro_5.mp4',
    'next_path': '/home',
    'next_video': 'home-loop'
  },
  'home-loop': {
    // 'url': 'https://d59nx84ake9tn.cloudfront.net/jazz-booth/Home_loop_all_stations_v4.mp4',
    'url': 'https://d59nx84ake9tn.cloudfront.net/jazz-booth/Home_loop_all_stations_v4.mp4',
    'next_path': ''
  },
  'trivia-game-loop': {
    // 'url': 'https://d59nx84ake9tn.cloudfront.net/jazz-booth/Game_station_loop_v2.mp4',
    'url': 'https://d59nx84ake9tn.cloudfront.net/jazz-booth/Game_station_loop_v2.mp4',
    'next_path': ''
  },
  'home-expert-videos': {
    // 'url': 'https://d59nx84ake9tn.cloudfront.net/jazz-booth/transitions/Home+zoom+to+experts+compressed.mp4',
    
    'url': 'https://va-email-assets.s3.amazonaws.com/jazz-booth/041521/Home-zoom-to-experts.mp4',
    'next_path': '/expert-videos'
  },
  'home-trivia-game': {
    // 'url': 'https://d59nx84ake9tn.cloudfront.net/jazz-booth/transitions/Home+zoom+to+game+compressed+2.mp4',
    'url': 'https://va-email-assets.s3.amazonaws.com/jazz-booth/041521/Home-zoom-to-game.mp4',
    'next_path': '/trivia-game0',
    'next_video': 'trivia-game-loop'
  },
  'home-resource-library': {
    // 'url': 'https://d59nx84ake9tn.cloudfront.net/jazz-booth/transitions/Home+zoom+to+resource+compressed2.mp4',
    'url': 'https://va-email-assets.s3.amazonaws.com/jazz-booth/041521/Home-zoom-to-resource.mp4',
    'next_path': '/resource-library'
  },
  'expert-videos-home': {
    // 'url': 'https://d59nx84ake9tn.cloudfront.net/jazz-booth/transitions/Experts+station+to+home+transition.mp4',
    'url': 'https://va-email-assets.s3.amazonaws.com/jazz-booth/041521/Experts-station-to-home-transition.mp4',
    'next_path': '/home',
    'next_video': 'home-loop'
  },
  'expert-videos-trivia-game': {
    // 'url': 'https://d59nx84ake9tn.cloudfront.net/jazz-booth/transitions/Expert+to+game+transition.mp4',
    'url': 'https://va-email-assets.s3.amazonaws.com/jazz-booth/041521/Expert-to-game-transition.mp4',
    'next_path': '/trivia-game0',
    'next_video': 'trivia-game-loop'
  },
  'trivia-game-expert-videos': {
    // 'url': 'https://d59nx84ake9tn.cloudfront.net/jazz-booth/transitions/Game+to+experts+transition.mp4',
    'url': 'https://va-email-assets.s3.amazonaws.com/jazz-booth/041521/Game-to-experts-transition.mp4',
    'next_path': '/expert-videos'
  },
  'trivia-game-resource-library': {
    // 'url': 'https://d59nx84ake9tn.cloudfront.net/jazz-booth/transitions/Game+to+resource+station+transition.mp4',
    'url': 'https://va-email-assets.s3.amazonaws.com/jazz-booth/041521/Game-to-resource-station-transition.mp4',
    'next_path': '/resource-library'
  },
  'resource-library-trivia-game': {
    // 'url': 'https://d59nx84ake9tn.cloudfront.net/jazz-booth/transitions/Resource+station+to+game+transition.mp4',
    'url': 'https://va-email-assets.s3.amazonaws.com/jazz-booth/041521/Resource-station-to-game-transition.mp4',
    'next_path': '/trivia-game0',
    'next_video': 'trivia-game-loop'
  },
  'resource-library-contact-us': {
    // 'url': 'https://d59nx84ake9tn.cloudfront.net/jazz-booth/transitions/Resource+station+to+home+transition.mp4',
    'url': 'https://va-email-assets.s3.amazonaws.com/jazz-booth/041521/Resource-station-to-home-transition.mp4',
    'next_path': '/contact-us'
  },
  'contact-us-resource-library': {
    // 'url': 'https://d59nx84ake9tn.cloudfront.net/jazz-booth/Virtual_Jazz_Booth_intro_2_compressed.mp4',
    'url': 'https://d59nx84ake9tn.cloudfront.net/jazz-booth/Virtual_Jazz_Booth_intro_2_compressed.mp4',
    'next_path': '/trivia-game0'
  },
  'contact-us-home': {
    // 'url': 'https://d59nx84ake9tn.cloudfront.net/jazz-booth/Virtual_Jazz_Booth_intro_2_compressed.mp4',
    'url': 'https://d59nx84ake9tn.cloudfront.net/jazz-booth/Virtual_Jazz_Booth_intro_2_compressed.mp4',
    'next_path': '/home'
  },
};

const JazzPlayer = ({ videoKey, videoToPlay, setVideoToPlay }) => {
  const history = useHistory();
  const playerRef = useRef(null);

  return (
        <ReactPlayer ref={playerRef} url={TransitionMap[videoKey].url} playing={videoToPlay === videoKey} loop={TransitionMap[videoKey].next_path ? false : true } muted playsinline className='transition-video' style={{ position: 'absolute', zIndex: 2, display: (videoToPlay === videoKey ? '' : 'none') }} width="100%" height="100%" onEnded={() => {
                if (TransitionMap[videoKey].next_path) {
                  if (TransitionMap[videoKey].next_video) {
                    setVideoToPlay(TransitionMap[videoKey].next_video);
		  }
		  history.push(TransitionMap[videoKey].next_path)
                  if (!TransitionMap[videoKey].next_video) {
                    setVideoToPlay('');
		  }
		}
		setTimeout(() => {
		  playerRef.current.seekTo(0);
		}, 1000);
	}} />
  );
}

const TransitionVideo = ({ videoToPlay, setVideoToPlay }) => {
  const history = useHistory();

  if (!isMobile) {
    return Object.keys(TransitionMap).map((videoKey) => (
        <JazzPlayer key={videoKey} videoKey={videoKey} videoToPlay={videoToPlay} setVideoToPlay={setVideoToPlay} />
    ));
  } else {
    if (videoToPlay) {
      history.push(TransitionMap[videoToPlay].next_path)
    }
  }
  return null;
};

export default TransitionVideo;
