import React from 'react';

const Page = (props) => {
  return (
    <div
      className="page"
      style={{ flex: 'auto', display: 'flex', flexDirection: 'column', position: 'relative', zIndex: 3 }}
    >
      {props.children}
    </div>
  );
};

export default Page;
