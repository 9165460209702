import React from 'react';
import Page from '../Page';
import styles from '../../styles/pages/home.module.scss';
import useWindowDimensions from '../Util';
import { isMobile } from "react-device-detect";
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


<script src="https://kit.fontawesome.com/70accd3f45.js" crossorigin="anonymous"></script>

const Home = ({ setVideoToPlay }) => {
  const { width, height } = useWindowDimensions();

  const settings = {
    arrows: false,
    slidesToShow: 1,
    centerMode: true
  };

  const getMiddleHitBoxWidth = () => {
    // if (height < width) {
    //   return `${((height / width) * .65) * 100}%`;
    // } else {
    //   return `${((width / height) * .65) * 100}%`;
    // }

    const screenRatio = (width/height);

    if (screenRatio > 1.5) {
      if ((screenRatio > 1.5) && (screenRatio < 1.6)) {
        return `${(45)}%`;
      } else if ((screenRatio > 1.6) && (screenRatio < 1.7)) {
        return `${(44)}%`;
      } else if ((screenRatio > 1.7) && (screenRatio < 1.8)) {
        return `${(42)}%`;
      } else if ((screenRatio > 1.8) && (screenRatio < 1.9)) {
        return `${(40)}%`;
      } else if ((screenRatio > 1.9) && (screenRatio < 2)) {
        return `${(39)}%`;
      } else if ((screenRatio > 2) && (screenRatio < 2.1)) {
        return `${(37)}%`;
      } else if ((screenRatio > 2.1) && (screenRatio < 2.2)) {
        return `${(35)}%`; 
      } else if ((screenRatio > 2.2) && (screenRatio < 2.3)) {
        return `${(33)}%`;
      } else {
        console.log('hello');
      }
    } else {
      if ((screenRatio > 1.4) && (screenRatio < 1.5)) {
        return `${(47)}%`;
      } else if ((screenRatio < 1.5) && (screenRatio > 1.4)) {
        return `${(47)}%`;
      } else if (screenRatio < 1.4) {
        return `${(48)}%`;
      } else {
        console.log('hello 2');
      }
    }

  };







  
  // // detects if iOS ...
  // function iOS() {
  //   return [
  //     'iPad Simulator',
  //     'iPhone Simulator',
  //     'iPod Simulator',
  //     'iPad',
  //     'iPhone',
  //     'iPod'
  //   ].includes(navigator.platform)
  //   // iPad on iOS 13 detection
  //   || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  // }

  // //detects orientation change ...
  // handleChange: function() {
  //   const self = this;          // Store `this` component outside the callback
  //   if ('onorientationchange' in window) {
  //       window.addEventListener("orientationchange", function() {
  //           // `this` is now pointing to `window`, not the component. So use `self`.
  //           self.setState({   
  //               orientation: !self.state.orientation
  //           })
  //           console.log("onorientationchange");
  //           if ((window.matchMedia("(orientation:landscape)").matches) && (iOS())) {
  //             // show messge.
  //           } else {
  //             // hide message
  //           }
  //       }, false);
  //   }


  if (isMobile) {
    return (
      <Page>
        <section className={styles.home}>
          <div className={`${styles['force-home-mobile-layout']}`}>
            <div className={`${styles['force-home-mobile-title-copy']}`}>EXPLORE THE<br />VIRTUAL BOOTH</div>
            {/* MAIN SLIDER ON HOMEPAGE */}
            <Slider {...settings} className={'home-slider'} style={{ marginTop: 5, width: "100%", marginRight: "auto", marginLeft: "auto" }}>
              <Link to="/expert-videos">
                <img alt="" src={"https://va-email-assets.s3.amazonaws.com/jazz-booth/Mobile_Video_001.png"} className={`${styles['scoreboard-img']}`} ></img>
              </Link>
              <Link to="/trivia-game">
                <img alt="" src={"https://va-email-assets.s3.amazonaws.com/jazz-booth/Mobile_Game_001.png"} className={`${styles['scoreboard-img']}`} ></img>
              </Link>
              <Link to="/resource-library">
                <img alt="" src={"https://va-email-assets.s3.amazonaws.com/jazz-booth/Mobile_Resources_001.png"} className={`${styles['scoreboard-img']}`} ></img>
              </Link>
              <Link to="/contact-us">
                <img alt="" src={"https://va-email-assets.s3.amazonaws.com/jazz-booth/Mobile_ContactUs_001.png"} className={`${styles['scoreboard-img']}`} ></img>
              </Link>
            </Slider>
            {/* END OF MAIN SLIDER ON HOMEPAGE */}
          </div>
        </section>
      </Page>
    );
  }

  return (
    <Page>
      <section className={styles.home}>
        
        <div style={{ display: `${isMobile ? 'none' : 'flex'}`, flexDirection: 'row', flex: 'auto' }}>
          <div style={{ flex: 1, cursor: 'pointer' }} onClick={() => setVideoToPlay("home-expert-videos")}>
            <div className={`${styles['station']} ${styles['station-one']}`}></div>
          </div>
          <div style={{ width: getMiddleHitBoxWidth(), cursor: 'pointer' }} onClick={() => setVideoToPlay("home-trivia-game")}>
            <div className={`${styles['station']} ${styles['station-two']}`}></div>
          </div>
          <div style={{ flex: 1, cursor: 'pointer' }} onClick={() => setVideoToPlay("home-resource-library")}>
            <div className={`${styles['station']} ${styles['station-three']}`}></div>
          </div>
        </div>

        
        <div className={`${styles['home-mobile-layout']}`}>
          <div className={`${styles['home-mobile-title-copy']}`}>EXPLORE THE<br />VIRTUAL BOOTH</div>
          {/* MAIN SLIDER ON HOMEPAGE */}
          <Slider {...settings} className={'home-slider'} style={{ marginTop: 5, width: "100%", marginRight: "auto", marginLeft: "auto" }}>
            <Link to="/expert-videos">
              <img alt="" src={"https://va-email-assets.s3.amazonaws.com/jazz-booth/Mobile_Video_001.png"} className={`${styles['scoreboard-img']}`} ></img>
            </Link>
            <Link to="/trivia-game">
              <img alt="" src={"https://va-email-assets.s3.amazonaws.com/jazz-booth/Mobile_Game_001.png"} className={`${styles['scoreboard-img']}`} ></img>
            </Link>
            <Link to="/resource-library">
              <img alt="" src={"https://va-email-assets.s3.amazonaws.com/jazz-booth/Mobile_Resources_001.png"} className={`${styles['scoreboard-img']}`} ></img>
            </Link>
            <Link to="/contact-us">
              <img alt="" src={"https://va-email-assets.s3.amazonaws.com/jazz-booth/Mobile_ContactUs_001.png"} className={`${styles['scoreboard-img']}`} ></img>
            </Link>
          </Slider>
          {/* END OF MAIN SLIDER ON HOMEPAGE */}
        </div>

      </section>
    </Page>
  );
};

export default Home;
