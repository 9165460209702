import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import ResourceTabs from '../ResourceTabs';
import styles from '../../styles/pages/resource-library.module.scss';
import { Link } from 'react-router-dom';

<script src="https://kit.fontawesome.com/70accd3f45.js" crossorigin="anonymous"></script>

const ResourceLibrary = ({ setVideoToPlay }) => {
  useEffect(() => {
    setVideoToPlay('');
  }, []);

  const renderPrevArrow = () => {
    if (isMobile) {
      return (
        <Link to="/trivia-game" style={{ textDecoration: 'none' }}>
          <div className="left-arrow">
            <i className="fas fa-arrow-left arrow-style"></i>
            <div className="left-right-text">Trivia<br />
            Game</div>
          </div>
        </Link>
      );
    }

    return (
      <div className="left-arrow" onClick={() => setVideoToPlay('resource-library-trivia-game')}>
        <i className="fas fa-arrow-left arrow-style"></i>
        <div className="left-right-text">Trivia<br />
        Game</div>
      </div>
    );
  };
  return (

    <div className={styles['resource-library']}>

      {renderPrevArrow()}

      <ResourceTabs />
      
      
      <Link to="/contact-us" style={{ textDecoration: 'none' }}>
        <div className="right-arrow">
          <i className="fas fa-arrow-right arrow-style"></i>
          <div className="left-right-text">Contact<br />
          Us</div>
        </div>
      </Link>

    </div>
  );
};

export default ResourceLibrary;
