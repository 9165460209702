import fetchWrap from 'fetch-wrap';

let apiUrl = 'http://trivia-api.beta.visual-a.com';
let gameUrl = 'http://trivia-game.beta.visual-a.com';
apiUrl = 'http://localhost:8000';
gameUrl = 'http://localhost:3001';

if (process.env.NODE_ENV === 'production') {
  apiUrl = 'https://api.narcolepsylinkbooth.com';
  gameUrl = 'https://game.narcolepsylinkbooth.com';
} else if (process.env.REACT_APP_APIENV === 'DEBUG') {
  apiUrl = 'http://localhost:8000';
  gameUrl = 'http://localhost:3000';
}

if (process.env.REACT_APP_APIURL !== undefined) {
  apiUrl = process.env.REACT_APP_APIURL;
}

const getUrlParams = (name) => {
  const results = new RegExp(`${name}=([^&]*)`).exec(window.location.href);
  if (results === null) {
    return null;
  }
  return decodeURI(results[1]);
};

const addJsonHeaders = () => {
  return async (url, options, fetch) => {
    if (!options.headers || !options.headers['Content-Type']) {
      options.headers = options.headers || {};
      options.headers['Content-Type'] = 'application/json';
    }
    return fetch(url, { ...options });
  };
};

const config = {
  baseApiUrl: apiUrl,
  gameUrl,
  fetch: fetchWrap(fetch, [addJsonHeaders()]),
  getUrlParams
};

export default config;
